<template>
  <div class="create-page-main">
    <div class="create-form">
      <div class="mx-1 row">
        <div class="form-title cpx-4 cpy-2 col-4">ページ編集</div>
        <div class="col-8 cpx-4 cpy-2">
          <b-button
            variant="primary"
            class="float-right btn-preview-fixed mr-0"
            v-on:click.prevent="preview()"
            v-b-modal.modal-detail-page
          >
            プレビュー
          </b-button>
        </div>
      </div>
      <b-form>
        <div class="mb-4">
          <label class="col-sm-12 cp-label"
            ><span>タイトル</span><span class="textMust">（必須）</span></label
          >
          <div class="cp-4">
            <b-form-input
              v-model="page_title"
              style="color: black"
              required
            ></b-form-input>
          </div>
        </div>
        <b-form-group id="input-group-8" label-for="input-8">
          <label class="col-sm-12 pl-0 cp-label"><span>説明:</span></label>
          <div class="w-100 cp-4">
            <input
              type="file"
              hidden
              ref="selectFile"
              id="idSelectFile"
              accept=".pdf"
              @change="changePDF($event)"
            />
            <div class="position-relative">
              <editor
                id="idEditor"
                @dataEditor="updatePage($event)"
                v-bind:dataEditor="
                  checkPreview == 2 ? JSON.stringify(dataEditor) : dataEditor
                "
                v-bind:isEdit="isEdit"
                class="mb-4"
              ></editor>
              <b-spinner
                v-if="loadingUpload"
                style="
                  width: 3rem;
                  height: 3rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                "
                large
              ></b-spinner>
            </div>
          </div>
        </b-form-group>
        <div class="d-flex justify-content-center mt-4 pb-4">
          <b-button
            v-on:click.prevent="editPage()"
            :disabled="isLoading"
            class="mr-4 btn-color"
            variant="success"
          >
            <span v-show="!isLoading"> 編集内容を更新 </span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </b-button>
          <b-button
            class="mr-4"
            variant="secondary"
            v-on:click="cancelUpdatePage()"
            >キャンセル</b-button
          >
        </div>
      </b-form>
    </div>
    <div>
      <b-modal id="modal-detail-page" hide-footer size="xl">
        <div
          class="row p-3"
          style="overflow-y: auto; max-height: 600px"
          v-if="pageCheck"
        >
          <div class="col-12">
            {{ pageCheck.page_title }}
          </div>
          <div class="col-12 py-4 accordion accordion-page">
            <div
              class="w-100"
              v-for="(itemBody, indexBody) in pageCheck.page_Content.blocks"
              :key="indexBody"
              :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
            >
              <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'paragraph'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :style="{
                  lineHeight:
                    itemBody.data.lineHeight &&
                    itemBody.data.lineHeight !== null
                      ? itemBody.data.lineHeight
                      : '',
                }"
              >
                <div v-html="itemBody.data.text"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'tableOfContents'"
              >
                <h3>目次</h3>
                <ul class="list-unstyled pl-4 listTableOfContent">
                  <li
                    v-for="(itemTOC, index) in itemBody.data.items"
                    :key="index"
                    class="pl-3"
                  >
                    <a
                      style="cursor: pointer"
                      v-scroll-to="{ el: '#' + itemTOC.href, offset: -20 }"
                      v-html="itemTOC.text"
                    ></a>
                  </li>
                </ul>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'header'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <h1
                  v-if="itemBody.data.level === 1"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h1>
                <h2
                  v-if="itemBody.data.level === 2"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h2>
                <h3
                  v-if="itemBody.data.level === 3"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h3>
                <h4
                  v-if="itemBody.data.level === 4"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h4>
                <h5
                  v-if="itemBody.data.level === 5"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h5>
                <h6
                  v-if="itemBody.data.level === 6"
                  class="mb-0"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  v-html="itemBody.data.text"
                ></h6>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'list'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <ul class="w-100" style="list-style-type: decimal">
                  <li
                    v-for="(itemList, indexList) in itemBody.data.items"
                    :key="indexList"
                    class="mb-2"
                    v-html="itemList"
                  ></li>
                </ul>
              </div>
              <div
                class="item-data w-100"
                v-if="itemBody.type === 'toggle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="w-100 d-flex align-items-center toggle-block__selector"
                  :id="itemBody.id"
                  :class="
                    itemBody.data.status === 'open'
                      ? 'toggle-open'
                      : 'toggle-close'
                  "
                >
                  <span
                    class="icon-toggle-panel mr-2"
                    @click="toggleBlock(itemBody)"
                  >
                    <b-icon
                      :icon="
                        itemBody.data.status === 'open' ? 'x-lg' : 'plus-lg'
                      "
                    ></b-icon>
                  </span>
                  {{ itemBody.data.text }}
                </div>
              </div>
              <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'code'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.code"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'raw'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : ''
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div v-html="itemBody.data.html"></div>
                <!-- <code>{{ itemBody.data.html }}</code> -->
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'table'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        v-for="(itemTblTh, indexTblTh) in itemBody.data
                          .content[0]"
                        :key="indexTblTh"
                        class="border-bottom-0"
                      >
                        <span class="w-100" v-html="itemTblTh"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(itemTblTr, indexTblTr) in itemBody.data
                        .contentTbody"
                      :key="indexTblTr"
                    >
                      <td
                        v-for="(itemTblTd, indexTblTd) in itemTblTr"
                        :key="indexTblTd"
                      >
                        <span class="w-100" v-html="itemTblTd"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'checklist'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div
                  class="custom-control custom-checkbox"
                  v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                  :key="indexCheckbox"
                >
                  <input
                    :id="'checkbox-' + itemBody.id + indexCheckbox"
                    type="checkbox"
                    :name="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-input"
                    :checked="itemCheckbox.checked"
                  />
                  <label
                    :for="'checkbox-' + itemBody.id + indexCheckbox"
                    class="custom-control-label ml-1"
                  >
                    <span v-html="itemCheckbox.text"></span>
                  </label>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'warning'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 bg-warning p-3 text-white">
                  <div class="w-100 font-weight-bold">
                    {{ itemBody.data.title }}
                  </div>
                  <hr />
                  <div class="w-100">
                    {{ itemBody.data.message }}
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'quote'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <blockquote
                  class="otro-blockquote"
                  :class="
                    itemBody.data.alignment === 'left'
                      ? 'text-left'
                      : 'text-right'
                  "
                >
                  <span>
                    {{ itemBody.data.caption }}
                  </span>
                  <span v-html="itemBody.data.text"></span>
                </blockquote>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'headerStyle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="titleStyle" :class="itemBody.data.class">
                  <h1 class="dataInput">
                    {{ itemBody.data.text }}
                  </h1>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'boxStylle'"
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
              >
                <div class="boxStyle" :class="itemBody.data.class">
                  <span
                    class="box-title px-2 border-0"
                    v-if="itemBody.data.title"
                    >{{ itemBody.data.title }}</span
                  >
                  <div
                    class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                  >
                    {{ itemBody.data.text }}
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'delimiter'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <div class="w-100 text-center ce-delimiter"></div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'image'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <a
                  v-if="replaceText(itemBody.data.linkImage)"
                  :href="itemBody.data.linkImage"
                >
                  <img :src="itemBody.data.file.url" alt="" width="auto" />
                </a>
                <img v-else :src="itemBody.data.file.url" alt="" width="auto" />
                <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'attachesPDF'"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <!-- <ViewPdf
                  v-bind:src="itemBody.data.file.url"
                  v-bind:idComponent="itemBody.id"
                /> -->
                <b-button
                  variant="primary"
                  class="btn-preview-fixed mr-0"
                  name="btn-view-PDF"
                  v-on:click.prevent="viewFilePDF(itemBody.data.file)"
                  v-b-modal.modal-detail-pdf
                >
                  {{ itemBody.data.title }}
                </b-button>
              </div>
              <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' &&
                  itemBody.data.service === 'youtube'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <YoutubePlayer
                  v-bind:idElemYoutube="itemBody.id"
                  v-bind:src="itemBody.data.source"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                :class="
                  itemBody.idChild
                    ? checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : 'my-3'
                "
                v-if="
                  itemBody.type === 'embed' && itemBody.data.service === 'vimeo'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <VimeoPlayer
                  v-bind:idElemVimeo="itemBody.id"
                  v-bind:src="itemBody.data.embed"
                  v-bind:heightPlayer="itemBody.data.height"
                  v-bind:widthPlayer="itemBody.data.width"
                  v-bind:dataVideo="itemBody.data"
                />
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100"
                v-if="itemBody.type === 'anyButton'"
                :class="
                  itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : checkIndex(itemBody)
                      ? 'toggle-block__item text-left toggle-item-last'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3'
                "
                :id-child="itemBody.idChild"
                :hidden="itemBody.showDisplay === true ? true : false"
              >
                <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                <a
                  v-if="
                    itemBody.data.type === null || itemBody.data.type === ''
                  "
                  class="anyButton"
                  :class="itemBody.data.style"
                  @click="downloadFile($event, itemBody.data)"
                >
                  {{ itemBody.data.text }}
                </a>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'roundButton' ||
                      itemBody.data.style === 'socialGlossySmooth' ||
                      itemBody.data.style === 'socialSquare')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <a
                    v-if="itemBody.data.twitter !== null"
                    :href="itemBody.data.twitter"
                    class="btn-social-circle btn-social-circle--twitter"
                    ><i class="fab fa-twitter"></i>
                  </a>
                  <a
                    v-if="itemBody.data.facebook !== null"
                    :href="itemBody.data.facebook"
                    class="btn-social-circle btn-social-circle--facebook"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    v-if="itemBody.data.pocket !== null"
                    :href="itemBody.data.pocket"
                    class="btn-social-circle btn-social-circle--pocket"
                    ><i class="fab fa-get-pocket"></i>
                  </a>
                  <a
                    v-if="itemBody.data.feedly !== null"
                    :href="itemBody.data.feedly"
                    class="btn-social-circle btn-social-circle--feedly"
                    ><i class="fas fa-rss"></i
                  ></a>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialGiza' ||
                      itemBody.data.style === 'socialSmartPhone')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div
                    :class="
                      itemBody.data.style === 'socialGiza'
                        ? 'btn-social-giza'
                        : 'btn-social-phone'
                    "
                  >
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-twitter'
                          : 'btn-social-phone-twitter ml-0 mr-3'
                      "
                      v-if="itemBody.data.twitter !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="3535"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-facebook'
                          : 'btn-social-phone-facebook ml-0 mr-3'
                      "
                      v-if="itemBody.data.facebook !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="5467"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-pocket'
                          : 'btn-social-phone-pocket ml-0 mr-3'
                      "
                      v-if="itemBody.data.pocket !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fab fa-get-pocket fa-stack-1x"></i>
                      </span>
                    </a>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-feedly'
                          : 'btn-social-phone-feedly ml-0 mr-3'
                      "
                      v-if="itemBody.data.feedly !== null"
                    >
                      <span class="fa-stack">
                        <i
                          class="fas fa-stack-2x"
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'
                          "
                        ></i>
                        <i class="fas fa-rss fa-stack-1x"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="w-100"
                  v-if="
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialFlat' ||
                      itemBody.data.style === 'socialIsometric')
                  "
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : checkIndex(itemBody)
                        ? 'toggle-block__item text-left toggle-item-last'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                        "
                      >
                        <i class="fab fa-twitter"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                        "
                      >
                        <i class="fab fa-facebook"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                        "
                      >
                        <i class="fab fa-get-pocket"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                  <div>
                    <a
                      href="1"
                      :class="
                        itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric'
                      "
                    >
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                        "
                      >
                        <i class="fas fa-rss"></i>
                      </span>
                      <span
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'
                        "
                        >TWEET</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div
                :id="itemBody.id"
                class="item-data w-100 my-3"
                v-if="itemBody.type === 'attachesFile'"
              >
                <div class="w-100 d-flex box-file-upload">
                  <div
                    class="iconFile"
                    :data-extension="itemBody.data.file.extension"
                    :style="{ color: itemBody.data.file.color }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="40"
                    >
                      <path
                        d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                      />
                    </svg>
                  </div>
                  <div class="fileName">
                    <div class="w-100">{{ itemBody.data.file.name }}</div>
                    <div
                      class="text-muted w-100"
                      v-html="convertSize(itemBody.data.file.size)"
                    ></div>
                  </div>
                  <div class="iconDowload ml-auto">
                    <a
                      class="iconDowload"
                      v-bind:download="itemBody.data.file.url"
                      rel="nofollow noindex noreferrer"
                      @click="downloadFileUpload($event, itemBody.data.file)"
                    >
                      <i class="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <b-modal id="modal-detail-pdf" hide-footer modal-class="modal-fullscreen">
      <ViewPdf
        v-bind:src="filePDFView && filePDFView.url"
        v-bind:idComponent="filePDFView && filePDFView.idComponent"
        v-bind:dataInfo="filePDFView"
      />
    </b-modal>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
import Editor from "../../components/editor/index.vue";
import { eventBus } from "../../main";
import ViewPdf from "../../components/viewPdf/index.vue";
// import ViewPdf from "../../components/viewPdf/pdfView.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";

export default {
  name: "EditPage",
  components: {
    // VueEditor,
    // draggable,
    Editor,
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return {
      isLoading: false,
      dataPage: [],
      page_title: "",
      page_content: "",
      chapter: [],
      chapter_title: "",
      position_chapter: "",
      description: "",
      title_chapter: "",
      chapter_description: "",
      chapter_position: "",
      dataDetailPage: {
        id: null,
        shop_id: null,
      },
      loadingUpload: false,
      dataEditor: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      isEdit: null,
      checkPreview: 1,
      pageCheck: null,
      filePDFView: null,
      // lastPagePDF: null,
      // timeWatchingEmbed: 0,
      // idLastPDF: null,
    };
  },
  created() {
    const { params } = this.$route;
    this.dataDetailPage.id = params.id;
    this.dataDetailPage.shop_id = this.shop_id;
    this.getPageById(this.dataDetailPage);
  },
  mounted() {
    let container = document.getElementsByClassName("ql-toolbar")[0];
    let item = document.createElement("span");
    item.classList.add("ql-formats");
    let input = document.createElement("button");
    input.addEventListener("click", this.toggleModal);
    input.setAttribute("type", "button");
    input.classList.add("ql-test");
    input.innerHTML = "PDF";
    item.appendChild(input);
    container.appendChild(item);
  },
  computed: {
    ...mapGetters(["pageById", "success", "message", "error"]),
  },
  watch: {
    pageById() {
      // const { id } = this.$route.params.id;
      // this.dataPage = this.pageById.filter((item) => item.id == id);
      this.page_title = this.pageById.page_title;
      if (typeof this.pageById.page_content != "object") {
        this.dataEditor = JSON.parse(this.pageById.page_content);
      } else {
        this.dataEditor = this.pageById.page_content;
      }
      this.isEdit = true;
      this.page_title = localStorage.getItem(Constants.REVIEW_PAGE)
        ? JSON.parse(localStorage.getItem(Constants.REVIEW_PAGE)).page_title
        : this.page_title;
      this.dataEditor = localStorage.getItem(Constants.REVIEW_PAGE)
        ? JSON.parse(localStorage.getItem(Constants.REVIEW_PAGE)).page_content
        : this.dataEditor;
      if (this.dataEditor) {
        this.checkPreview = 2;
      }
      // this.chapter = this.dataPage[0].chapter;
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.isLoading = false;
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({ getPageById: "getPageById" }),
    ...mapActions({ updatePageByID: "updatePageByID" }),
    createChapterOther() {
      if (
        this.title_chapter !== "" ||
        this.chapter_description !== "" ||
        this.chapter_position !== ""
      ) {
        this.chapter.push({
          title: this.title_chapter,
          description: this.chapter_description,
          position_chapter: this.chapter_position,
        });
        this.showBtnView = true;

        this.$toasted.success("正常に登録");
        this.title_chapter = "";
        this.chapter_description = "";
        this.chapter_position = "";
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-chapter");
        });
      } else {
        this.$toasted.error("チャプター登録に失敗しました");
      }
    },
    deleteChapter() {},
    editPage() {
      this.checkPreview = 1;
      eventBus.$emit("getFormEditor");
    },
    async updatePage(e) {
      const idPage = this.$route.params.id;
      this.isLoading = true;
      const formUpdate = {
        shop_id: this.shop_id,
        id: idPage,
        page_title: this.page_title,
        page_content: e,
      };
      if (this.checkPreview == 2) {
        // localStorage.setItem(Constants.REVIEW_PAGE, JSON.stringify(formUpdate));
        // this.$router.push({
        //   name: this.$route.params.shopId ? "ReviewPage" : "ReviewPage domain",
        // });
        const detail = {
          page_title: this.page_title,
          page_Content: e,
        };
        this.pageCheck = detail;
        this.pageCheck.page_Content.blocks.map((item) => {
          if (item.type === "table") {
            item.data.contentTbody = item.data.content.filter(
              (item, index) => index > 0
            );
            return item;
          }
          //
          var blocksData = this.pageCheck.page_Content.blocks;
          if (item.type === "toggle") {
            item.data.status = "closed";
            const indexBlockToggle =
              this.pageCheck.page_Content.blocks.indexOf(item);
            if (item.data.child.length >= item.data.items) return;
            for (let i = 1; i <= item.data.items; i++) {
              item.data.child.push(blocksData[indexBlockToggle + i]);
              if (item.data.status === "closed")
                blocksData[indexBlockToggle + i].showDisplay = true;
              blocksData[indexBlockToggle + i].idChild = item.id;
            }
          }
        });
        this.isLoading = false;
      } else {
        const request = await this.$store.dispatch(
          "updatePageByID",
          formUpdate
        );
        if (request.success === true) {
          localStorage.removeItem(Constants.REVIEW_PAGE);
          this.$router.push({
            name: this.$route.params.shopId ? "list page" : "list page domain",
          });
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    cancelUpdatePage() {
      this.checkPreview = 1;
      localStorage.removeItem(Constants.REVIEW_PAGE);
      this.$router.push({
        name: this.$route.params.shopId ? "list page" : "list page domain",
      });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
      } else {
        if (file.size / 1024 / 1024 > 10) {
          this.$toasted.error("画像1枚につき10MBまで");
          resetUploader();
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.shop_id);

          const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
          const method = "POST";
          await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              const url = response.data.data; // Get url from response
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch(() => {});
        }
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.shop_id);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        this.loadingUpload = true;
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.chapter_description +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
            this.loadingUpload = false;
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
    preview() {
      //
      this.checkPreview = 2;
      eventBus.$emit("getFormEditor");
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkIndex(toggle) {
      var listToggle = this.pageCheck.page_Content.blocks.filter(
        (item) => item.idChild === toggle.idChild
      );
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },
    async viewFilePDF(file) {
      this.$store.commit("set", ["clickOnPDF", true]);
      this.filePDFView = file;
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.accordion-page .toggle-block__item {
  margin: unset;
  max-width: unset;
}
.accordion-page img {
  max-width: 100% !important;
}
</style>
